import type { SeamlyConfig } from '@apps/chat/shared/multilabel/src/ChatProvider';

export const businessUnit = 'be';
export const countryCode = 'BE';
export const label = 'eneco';
export const name = 'be-eneco-main';
export const uuid = 'LePAAt';
export const seamlyConfig: SeamlyConfig = {
  defaultNamespace: 'consumer-be',
  instance: 'eneco-be',
  defaultLocale: 'nl-informal',
  defaultTopic: 'on_start',
};
